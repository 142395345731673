.headerTriggers {
  width: 30%;
  min-width: 15rem;
}

.tableActionsItems {
  width: 6.25rem;
}

.headerSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: 3.125rem;
  flex-wrap: wrap;
}

.tableActionCell {
  text-align: center;
  display: flex;
  justify-content: space-around;
}

.chBoxes {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  gap: 1.5rem;
  padding: 1.125rem 0 0 1.5rem;
}

.tableCell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  & div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}