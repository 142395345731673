@import '../../assets/scss/vars';

.nav {
  min-height: 100%;
  background: #fff;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 80px;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
  z-index: 100;

  // Стили для элементов в закрытом состоянии
  & div>div>div {
    flex-direction: column;
    font-size: 10px;
    text-align: center;

    &>span {
      margin-left: 0;
      line-height: 12px;
    }

    & :nth-child(1) {
      margin-top: 5px;
      height: 25px;
      width: 25px;
    }
  }
}

.closeMenu {
  width: 142px;
  min-width: 142px;

  & div {
    min-width: 142px;

    &>div {
      &>div {
        flex-direction: row;
        font-size: 14px;
        text-align: left;
        opacity: 1;

        &>span {
          display: flex;
          align-items: center;
          margin-left: 4px;
          flex-grow: 0;
        }

        & :nth-child(1) {
          margin-top: 0px;
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}

.disabledNav:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
}

.menuItemBlock {
  text-align: center;

}