@import '../../assets/scss/mixins';
@import '../../assets/scss/vars';

.table {
  overflow: hidden;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  margin: 0.5rem;
}

.tableContent {
  overflow: scroll;
  position: relative;
  background: #FFF;
  height: 100%;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFF;
  border-bottom: 1px solid #e9e9e9;
  margin-right: 0.4375rem;
}

.tableHeaderItem {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: center;
  padding: 0;
  margin: 0;
  background: #FFF;
  height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 1024px) {
  .table {

    & div,
    button,
    p,
    span {
      font-size: 0.65rem;
    }
  }
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchNothing {
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-bottom: 1px solid #e9e9e9;
  padding: 0 0.4375rem 0 0;
}

.sortable {
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.sortIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

@include scroll('.tableContent');