@import '../../assets/scss/vars';

.action {
  width: 2rem;
  height: 2rem;
  background: #deecf9;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    background: darken(#deecf9, 5%);
  }

  &:focus {
    outline: 2px solid #333333;
  }
}

.largeAction {
  width: auto;
  padding: 0 0.5rem;
}

.icon {
  color: #004578;
  font-size: 1.125rem;
}

.text {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #004578;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.danger {
  background: $danger;

  &:hover {
    background: darken($danger, 5%);
  }

  .icon {
    color: #a80000;
  }

  .text {
    color: #a80000;
  }
}

.accent {
  background: $accent;

  &:hover {
    background: darken($accent, 5%);
  }

  .icon {
    color: #fff;
  }

  .text {
    color: #fff;
  }
}

.mainContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (min-width: 868px) {
  .mainContainer {
    justify-content: flex-start;
  }
}

.infoIcon {
  max-width: 1.125rem;
  width: 63%;
  height: 63%;
  align-self: center;
}
