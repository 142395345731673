@import 'vars';
@import 'mixins';
@import 'media';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $font;
}

html {
  font-size: 16px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  display: inline-block;
}

textarea,
button,
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  outline: none;
  font-family: $font;

  &[disabled] {
    cursor: default;
    opacity: 0.7;
  }
}

button {
  cursor: pointer;
}

@include scroll(body);

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.container {
  display: flex;
  flex: 1;
}

.wrapper {
  width: calc(100% - 80px);
  max-height: calc(100vh - 19rem);
}

.content {
  background: #F8F8F8;
  position: relative;
  height: calc(100vh - 9.8rem);
}

.contentWrapper {
  // min-height: calc(100vh - 9rem);
  background: #fff;
  padding: 1.875rem 3.75rem;
  height: calc(100vh - 9rem);
  width: calc(100%);
  position: relative;
}

.searchBlockWrapper {
  align-items: center;
  color: black;
  min-width: 28.625rem;

}

.titleWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: black;
  padding: 0.75rem 1rem;
  background-color: #F3F2F1;
}

// .contentComponent {
//   margin: 0.75rem 1rem;
// }



.title {
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 400;
}

.tableItem {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;
}

.errorTableItem {
  background-color: $danger;
  border-bottom: 1px solid darken($danger, 5%);
}

.tableRow {
  text-align: center;
  overflow: hidden !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableRowFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

.code {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.875rem;
  flex-wrap: wrap;
}

.headerTitle {
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 500;
  min-width: 10.625rem;
  margin: 0 1rem;
}

.headerTitleWrapper {
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 500;
  min-width: 10.625rem;
}

.headerTriggers {
  display: flex;
  min-width: 29.6875rem;
  width: 50%;
  justify-content: space-between;
  align-items: center;
}

.search {
  width: 25%;
}

.dateInputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0 !important;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableActionsItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.errorMessageText {
  font-size: 12px;
  color: $error;
  font-weight: 400;
  position: absolute;
  left: 0;
  bottom: -20px;
}

.headerFilters {
  display: flex;
  align-items: center;
  gap: 9px;
}

.filtersWrapper {
  align-items: center;
  margin: 0.75rem 1rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.tableCell {
  text-align: center;
  display: flex;
  justify-content: center;
}

.filtersMainWrapper {
  display: flex;
  gap: 10px;
}

.searchWithFiltersVoid {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40vh;
  color: #ee5f2b;
  font-size: 1rem;
}