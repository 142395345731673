.calloutWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: underline;
  color: #0078d4;
  cursor: pointer;
}

.informationBoxUP {
  z-index: 15;
  position: absolute;
  display: flex;
  top: -8.9rem;
  left: -2rem;
  width: 13.5rem;
  min-height: 7rem;
  padding: 15px 12px;
  background-color: #f8f8f8;
  padding: 5px 12px;
  border-radius: 0.5rem;
  color: #201f1e;
  box-shadow: 0px 1.2000000476837158px 3.5999999046325684px 0px #0000001a;
  &::after {
    border: 0.9rem solid;
    border-color: #f8f8f8 transparent transparent;
    content: '';
    left: 45%;
    margin-left: -10px;
    position: absolute;
    top: 100%;
  }
  @media (min-width: 624px) {
    top: -12rem;
    left: -3rem;
    min-height: 11rem;
  }
  @media (min-width: 960px) {
    top: -12rem;
    left: -2rem;
    min-height: 11rem;
  }
  @media (min-width: 1026px) {
    min-height: 7rem;
    top: -8.9rem;
    left: -3.8rem;
  }
  @media (min-width: 1100px) {
    left: -3.5rem;
  }
  @media (min-width: 1150px) {
    left: -3.15rem;
  }
  @media (min-width: 1200px) {
    left: -3rem;
  }
  @media (min-width: 1250px) {
    left: -2.7rem;
  }
  @media (min-width: 1300px) {
    left: -2.3rem;
  }
  @media (min-width: 1400px) {
    left: -2.2rem;
  }
  @media (min-width: 1450px) {
    left: -2rem;
  }
  @media (min-width: 1500px) {
    left: -1.8rem;
  }
  @media (min-width: 1600px) {
    left: -1.45rem;
  }
  @media (min-width: 1700px) {
    left: -1.1rem;
  }
  @media (min-width: 1800px) {
    left: -0.8rem;
  }
  @media (min-width: 1850px) {
    left: -0.45rem;
  }
  @media (min-width: 1900px) {
    left: -0.3rem;
  }
  @media (min-width: 2000px) {
    left: 0;
  }
  @media (min-width: 2200px) {
    left: 0.6rem;
  }
  @media (min-width: 2300px) {
    left: 1rem;
  }
  @media (min-width: 2400px) {
    left: 2rem;
  }
}

.informationBoxBottom {
  z-index: 15;
  position: absolute;
  display: flex;
  top: 170%;
  left: -2.2rem;
  min-width: 13.5rem;
  max-width: 13.5rem;
  min-height: 7rem;
  padding: 15px 12px;
  background-color: #f8f8f8;
  padding: 5px 12px;
  border-radius: 0.5rem;
  color: #201f1e;
  box-shadow: 0px 1.2000000476837158px 3.5999999046325684px 0px #0000001a;
  &::before {
    border: 0.9rem solid;
    border-color: transparent transparent #f8f8f8 transparent;
    content: '';
    left: 45%;
    margin-left: -10px;
    position: absolute;
    top: -1.55rem;
  }
  @media (min-width: 624px) {
    left: -4.3rem;
    height: 11rem;
  }
  @media (min-width: 700px) {
    left: -3.3rem;
  }
  @media (min-width: 800px) {
    left: -3rem;
  }
  @media (min-width: 960px) {
    left: -2.2rem;
  }
  @media (min-width: 1024px) {
    min-height: 7rem;
    max-height: 7rem;
    left: -3.7rem;
  }
  @media (min-width: 1100px) {
    left: -3.3rem;
  }
  @media (min-width: 1200px) {
    left: -2.75rem;
  }
  @media (min-width: 1300px) {
    left: -2.6rem;
  }
  @media (min-width: 1400px) {
    left: -2.2rem;
  }
  @media (min-width: 1500px) {
    left: -1.7rem;
  }
  @media (min-width: 1600px) {
    left: -1.5rem;
  }
  @media (min-width: 1700px) {
    left: -1.1rem;
  }
  @media (min-width: 1800px) {
    left: -0.7rem;
  }
  @media (min-width: 1850px) {
    left: -0.5rem;
  }
  @media (min-width: 1900px) {
    left: -0.3rem;
  }
  @media (min-width: 1950px) {
    left: -0.15rem;
  }
  @media (min-width: 2000px) {
    left: 0.1rem;
  }
  @media (min-width: 2050px) {
    left: 0.25rem;
  }
  @media (min-width: 2100px) {
    left: 0.3rem;
  }
  @media (min-width: 2200px) {
    left: 0.8rem;
  }
  @media (min-width: 2300px) {
    left: 1.2rem;
  }
  @media (min-width: 2400px) {
    left: 2rem;
  }
}

.informationBoxProdPage {
  z-index: 15;
  position: absolute;
  display: flex;
  top: -8rem;
  left: -0.7rem;
  width: 14rem;
  height: 7rem;
  padding: 15px 12px;
  background-color: #f8f8f8;
  padding: 5px 12px;
  border-radius: 0.5rem;
  color: #201f1e;
  box-shadow: 0px 1.2000000476837158px 3.5999999046325684px 0px #0000001a;
  &::after {
    border: 0.9rem solid;
    border-color: #f8f8f8 transparent transparent;
    content: '';
    left: 75%;
    margin-left: -10px;
    position: absolute;
    top: 100%;
  }
}

.longerString {
  left: 0;
}

.longString {
  left: 1rem;
}

.callout {
  width: 15rem;
  height: 9.5rem;
  padding: 0.5rem;
  overflow: auto;
}

.loading {
  -webkit-font-smoothing: antialiased;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  font-family: 'Segoe UI';
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.backDrop{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  opacity: 0.2;
  z-index: 6;
}