.tableActionCell {
    text-align: center;
    display: flex;
    justify-content: space-around;
}

.tableItem {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;

    & div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
    overflow: hidden;
}

.mainContent {
    flex: 1;
    min-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.tableWrapper {
    flex: 1;
    min-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}