.search {
  width: 30%;
}

.headerSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerFilters {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: 3.125rem;
  flex-wrap: wrap;
}
