.selectContainer {
  display: flex;
  flex-direction: column;
}

.label {
  font-family: Segoe UI, sans-serif;
  font-weight: 600;
  color: rgb(50, 49, 48);
  font-size: 14px;
  overflow-wrap: break-word;
}

.select {
  margin-top: -10px;
}
