.checkBoxWrapper {
  display: flex;
  gap: 1.3rem;
  align-items: center;
  margin: 2rem 2rem;
}

.singleCheckbox {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

.checkboxText {
  text-align: center;
}


.tableItem {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;

  & div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}