@import '../../assets/scss/vars';

.container {
  width: 100%;
  background: $titlebar;
  height: $topLineHeight;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 1.125rem 1rem 1rem 1.125rem;
}

.containeFlexSwitch {
  justify-content: space-between;
  padding-bottom: 0;
}

.menuBar {
  display: flex;
  flex-direction: row;
}

.filters {
  display: flex;
  width: 20%;
  justify-content: flex-end;
  align-items: center;
}

.filtersBtn {
  display: flex;
  align-items: center;
}

.filtersTitle {
  margin-left: 0.9375rem;
  font-size: 1rem;
  display: inline-block;
  line-height: 1;
  margin-bottom: 0.125rem;
}

.title {
  color: $hoverColor;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625rem;
  display: flex;
  align-items: center;
}

.filterIcon {
  font-size: 1.125rem;
  color: $accent;
  width: 1.125rem;
  height: auto;
}

.crumbs {
  width: 80%;
  display: flex;
}

.crumbIcon {
  display: inline-block;
  margin: 0.125rem 0.9375rem 0;
}

.crumb {
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: #333333;
}

.curCrumb {
  font-weight: 500;
}