@import '../../../assets/scss/vars';
@import '../../../assets/scss/mixins';

.actions {
  display: flex;
  width: 7rem;
  justify-content: space-between;
  align-items: center;
}

.mainInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.code {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.remains {
  color: gray;

  b {
    color: #000;
    font-weight: 500;
  }
}

.mainInputs {
  width: 50%;
}

.inputsContainer {
  display: flex;
  justify-content: space-between;
}

.infoHeader {
  width: 100%;
  margin-top: 1.875rem;
  background: #ffffff;
  border-radius: 4px;
  padding: 0.9375rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoTitle {
  font-size: 0.875rem;
  line-height: 1rem;
}

.infoIcon {
  font-size: 1rem;
  color: #0078d4;
  font-weight: 700;
}

.infoContent {
  width: 100%;
  background: #fff;
  padding: 0.9375rem 1.25rem;
  display: none;
  max-height: 31.25rem;
  overflow: auto;
}

.infoContentShowed {
  display: block;
}

.panel {
  max-height: 75vh;
  overflow: auto;
  padding-bottom: 4rem;
}

.codesOrder {
  margin-top: 25px;
  width: 100%;
}

.codesOrderTitle {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.codesOrderRow {
  display: flex;
  align-items: flex-end;
}

.codesOrderInput {
  margin-right: 15px;
  width: 400px;
}

.buttons {
  left: 0;
  position: fixed;
  background: #f8f8f8;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.375rem;
  bottom: 0;
}

.infoRow {
  width: 100%;
}

.datePickerWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.errorMsg {
  margin-top: 0.5rem;
  font-size: 12px;
  color: $error;
  font-weight: 400;
  left: 0;
  bottom: -20px;
}

.infoKey {
  text-decoration: underline;
  width: 100%;
  margin-bottom: 0.625rem;
}

.infoValue {
  width: 100%;
  font-size: 0.875rem;
}

.markCodeWrapper {
  display: flex;
  flex-direction: column;
  padding: 0.15rem;
  width: 13.5rem;
  height: 7rem;
  gap: 0.3rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  font-family: 'Segoe UI';
}

.markeingCodesHeadLine {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.periodsWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
}

.singleOptionRow {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

@include scroll('.panel');
@include scroll('.infoContent');
