
@mixin tableText {
    font: {
        family: 'Segoe UI';
        style: normal;
        weight: 350;
        size: 0.975rem;    
        color: #333333;   
    }
    line-height: 1.25rem;
}

.tableActionCell {
    text-align: center;
    display: flex;
    justify-content: space-around;
}

.mainContainer {
    margin-top: 3.125rem;
}

.additionalTableProps{   
    max-width: calc(100vw - 180px - 10rem);
    min-width: calc(100vw - 180px - 20rem);
}

.additionalHeaderProps{
    justify-content: flex-start;    
}

.balanceTableColumns {
    justify-content: flex-start;   
    @include tableText;
}

.minBalanceColumn {
    justify-content: flex-end;   
}

.balanceTitle {
    width: 14,625rem;
    height: 1.25rem;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #333333;
}

.titleWrapper {
    margin-bottom: 1.25rem;
  }

.minBalanceInput {
    width: 10rem;
    height: 1.625rem;
    display: flex;
    justify-content: flex-start;
   // background-color: #FFFFFF;
    color: #201F1E;
    border: 0.5px solid #201F1E;
    border-radius: 2px;
    padding-left: 0.625rem;
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
    @include tableText;
}

.lowBalance {
    background-color:  #FDE7E9;
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 6.6875rem;
    padding-right: 1.2rem;
}