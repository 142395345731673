@import "../../assets/scss/vars";

.headerTitle {
  width: 100%;
  padding-bottom: 1.25rem;
  font-weight: 700;
  border-bottom: 1px solid #E9E9E9;
}

.productsActionsItems {
  display: flex;
  justify-content: center;
}

.lineTableFooter {
  margin-top: 0.9375rem;
  display: flex;
  justify-content: space-between;
}

.tables {
  padding-bottom: 6.25rem;
}

.buttons {
  left: 0;
  position: fixed;
  background: #F8F8F8;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.375rem;
  bottom: 0;
}

.inputRow {
  width: 18.75rem;
  margin-bottom: 1.25rem;
}

.error {
  color: $red;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
}

.addBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}