@import '../../assets/scss/vars';

.paginationBlock {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0.75rem 1rem 0;
  justify-content: space-between;
  padding-right: 5px;
}

.paginationBlockElement {

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;

  li {
    width: 3.4375rem;
    height: 1.5rem;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #979797;
    display: flex;
    justify-content: center;
    background: transparent;
    align-items: center;
    font-weight: 500;
    border-radius: 4px;

    &:hover:not(.paginationActivePage) {
      background: lightgray;
      color: #000;
    }

    a {
      display: flex;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 1;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      &:focus {
        outline: none;
      }
    }
  }
}

.paginationActivePage {
  background: $accent !important;
  color: #fff !important;
}

.chevronIcons {
  max-width: 1.125rem;
  height: auto;
}