@import '../../assets/scss/vars';
@import '../../assets/scss/mixins';

.reportDates {
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  position: relative;

  .code {
    margin-bottom: 0.3125rem;
    text-align: right;
  }
}

.aggregationCode {
  position: absolute;
  left: 0;
}

.reportStatus {
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.75rem;
  font-weight: 400;
}

.modal {
  padding: 1.5rem;
  overflow: auto;
  min-width: 50rem;
  min-height: 22rem;
  max-width: 53rem;
  overflow-y: auto;
  display: flex;
}

.modalMainWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}

.modTitle {
  display: flex;
  font-style: normal;
  flex-wrap: wrap;
  font-weight: 600;
  font-size: 1.075rem;
  overflow: hidden;
  font-family: Segoe UI, sans-serif;
  word-spacing: 5px;
  text-justify: inter-word;
  text-align: justify;
  padding: 0.75rem;
  margin-bottom: 1rem;
}

.formMainWrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
  padding-top: 1rem;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  gap: 0.75rem;
}

.rowWrapper {
  display: flex;
  gap: 1.3rem;
  max-height: 3rem;
}

.buttonRow {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}

.columnTitles {
  font-size: 0, 75rem;
  font-family: 'Segoe UI';
  display: flex;
  padding: 0.75rem 0;
  background-color: rgb(0, 120, 212);
  color: white;
  align-items: center;
  width: 100%;
  gap: 1.3rem;
}

.cell {
  width: 9rem;
  max-width: 9rem;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expCell {
  width: 18rem;
  max-width: 18rem;
  display: flex;
  gap: 0.5rem;
  display: flex;
  justify-content: center;
  max-height: 100%;
}

.btn {
  width: 25%;
  height: 2rem;
}

.textField {
  color: black;
  align-self: flex-start;

  &:disabled {
    color: black;
  }
}

.date {
  width: 9rem;

  & span {
    display: block;
  }
}

.time {
  width: 7rem;
}

.bottomPart {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  align-items: flex-end;
}

.correctionText {
  display: flex;
  align-self: self-end;
  font-family: 'Segoe UI', sans-serif;
}

.reportTitle {
  font-size: 1.25rem;
  padding-left: 0.4rem;
}

.errorMessage {
  display: flex;
  justify-content: end;
  width: 98%;
  font-size: 12px;
  color: $error;
  font-weight: 400;
  margin-bottom: 6px;

  & span {
    top: 55%;
  }
}