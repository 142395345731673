@import '../../assets/scss/vars';

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  justify-content: space-between;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.tableCell {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.messageWrapper {
  display: flex;
  flex-shrink: 0;
  min-width: 30rem;
  width: 40%;
}

.tooltipContainer {
  margin-left: 0.2rem;
}

.bottomPart {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  align-items: flex-end;
}

.correctionText {
  display: flex;
  align-self: self-end;
  font-family: 'Segoe UI', sans-serif;
}

.custHeaderCell {
  background-color: orange;
}

.tableContainer {
  background: #FFF;
  position: relative;
  height: 100%;
  min-height: 0;
  overflow: auto;
  padding: 0.5rem;
  border-radius: 0.5rem;

  &::-webkit-scrollbar {
    width: .5rem;
    max-height: 0.5rem;
    min-width: 7px;
    z-index: 2;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    z-index: 2;
    margin-top: 44px;
  }

  &::-webkit-scrollbar-thumb {
    border-left: 1px solid white;
    z-index: 2;
    background: $accent;
    border-radius: 0;
  }
}

.table {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tableContent {
  overflow: auto;
  min-height: 0;
  padding-right: 0.4375rem;
  background-color: #ffffff;
  position: relative;

  td {
    height: 43px;
    max-height: 43px;
    min-height: 43px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    z-index: 2;
  }

  &::-webkit-scrollbar-thumb {
    border-left: 1px solid white;
    z-index: 2;
    background: $accent;
    border-radius: 0;
  }
}

.tableHeader {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  border-collapse: collapse;
  text-align: center;
  max-height: 44px;
  min-height: 44px;
  height: 44px;
  margin-right: 0;
  border-bottom: 1px solid #e9e9e9;
  padding-right: 0.4375rem;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 43px;
    padding: 0;
    white-space: wrap;
    text-overflow: ellipsis;
  }

  span {
    color: $accent;
  }
}

.tableRow {
  max-height: 44px;
  min-height: 44px;
  height: 44px;
  position: relative;
  display: flex;
  width: 100%;
  cursor: pointer;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: center;
    padding: 0;

    & div {
      max-width: 100px;
    }

    & img {
      margin: auto;
    }
  }
}

.tableSelectionCell {
  border-bottom: 1px solid #0f6cbd !important;
}

.tableWrapper {
  height: 100%;
}

.tableBlock {
  display: flex;
  padding: 1rem;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  min-height: 0;
  position: relative;
}

.disabledCheckbox {
  cursor: not-allowed;
  opacity: 0.5;

  & span {
    cursor: not-allowed;
  }
}

@media (max-width: 1100px) {
  .table {
    font-size: 11px;
  }

  .table button {
    font-size: 11px;
  }

  .table img {
    height: 34px;
    width: 90px;
  }

  #status {
    & img {
      min-height: 30px;
      max-width: 70px;
    }
  }
}

//здесь указываем ширину столбца и выравнивание заголовка и содержимого столбца
#productName {
  text-align: start;
  width: 15%;
  overflow: hidden;
  justify-content: flex-start;
  max-width: 200px;

  div {
    justify-content: flex-start;
  }
}

#numberMarkingCodes {
  overflow: hidden;
}

#status {
  width: 15%;

  & img {
    max-height: 44px;
    width: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#creationDate {
  overflow: hidden;

}

#sendingDate {
  overflow: hidden;

}

#productCreationDate {}

#numberMarkingCodes {
  overflow: hidden;
  justify-content: center;
}

#productGtin {
  overflow: hidden;
}

#vsd {
  overflow: hidden;
}

#suzId {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.searchNothing {
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}

// Добавьте стили для react-window
.ReactWindow {
  flex: 1 1 auto;
  outline: none;
  overflow-x: visible;
  padding-right: 0.4375rem;
  overflow: hidden !important;

  &::-webkit-scrollbar-track {
    background: transparent;
    z-index: 2;
  }

  &::-webkit-scrollbar-thumb {
    border-left: 1px solid white;
    z-index: 2;
    background: $accent;
    border-radius: 0;
  }
}